<template>
  <div class="content-container">
    <PageTitle :title="$t('PRIZES.TITLE')" class="page-title">
      <template #extra-content>
        <button type="button" class="pink-button create-button" @click="createNewPrize()">
          {{ $t('PRIZES.CREATE') }}
        </button>
      </template>
    </PageTitle>
    <h1 class="page-section-title section-title">{{ $t('PRIZES.OVERVIEW') }}</h1>

    <CustomTable v-if="tableData"
                 :options="prizesTableOptions"
                 :table-data="tableData"
                 :pagination-data="paginationData"
                 :empty="empty"
                 :empty-state-data="emptyStateData"
                 table-class="class-Prizes"
    >
      <template #order="props">
        <div>{{ props.rowData.id }}</div>
      </template>
      <template #photo="props">
        <img :src="props.rowData.photo" width="50">
      </template>
      <template #title="props">
        <div>{{ props.rowData.title }}</div>
      </template>
      <template #createdAt="props">
        <div>{{ new Date(props.rowData.createdAt).toLocaleDateString('nl') + ' ' + new Date(props.rowData.createdAt).toLocaleTimeString('nl') }}</div>
      </template>
      <template #actions="props">
        <div>{{ props.rowData.province }}
          <div class="action-button-group">
            <ActionButton :text="$t('DELETE')"
                          :icon="require('../../../assets/icons/icn_delete.svg')"
                          custom-class="button-red"
                          class="action"
                          @click="deletePrize(props.rowData)"/>
            <ActionButton :text="$t('EDIT')"
                          :icon="require('../../../assets/icons/icn_edit.svg')"
                          custom-class="button-blue"
                          class="action"
                          @click="editPrize(props.rowData)"/>
          </div>
        </div>
      </template>
      <template #right-side-info>
        <p><b>{{ totalItems || 0 }} </b>
          {{ $t('PRIZES.TOTAL_PRIZES') }} </p>
      </template>
    </CustomTable>
    <BasicModal v-if="isBasicVersionDisplayed"/>

  </div>

</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'

import CustomTable from '@/components/table/CustomTable'
import { getTableOptions, PRIZES_TABLE } from '@/utils/helpers/customTable/CustomTableHelper'
import { ROUTE_NAMES_CMS } from '@/router/modules/cms'

import { computed, ref } from '@vue/runtime-core'
import ActionButton from '@/components/table/ActionButton.vue'
import nl from '@/utils/language/nl.json'
import ModalController, { MODAL_NAMES } from '@/utils/helpers/basicModal/ModalController'
import { BASIC_MODAL_CONTENT } from '@/models/basicModal/BasicModalContent'
import BasicModal from '@/components/elements/basicModal/BasicModal'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'


export default {
  name: 'Prizes',
  components: {
    PageTitle,
    CustomTable,
    ActionButton,
    BasicModal,
  },
  setup() {
    const router = useRouter()
    const store = useStore()

    const modalController = ref(new ModalController())

    const activeModal = computed(() => store.getters.getActiveBasicModal)

    const isBasicVersionDisplayed = computed(() =>
      activeModal.value === MODAL_NAMES.DELETE_PRIZE
    )

    //API data similar to that of other calls
    const fakeApiData = {
      totalItems: 4,
      currentPage: 1,
      totalPages: 3,
      amountOfItemsPerPage: 15,
      items: [
        {
          id: 0,
          title: 'first prize',
          photo: 'https://westonconferences.com/cmoon_images/20191212205249801_award.jpg',
          createdAt: '01-01-01 2020'
        },
        {
          id: 1,
          title: 'second prize',
          photo: 'https://westonconferences.com/cmoon_images/20191212205249801_award.jpg',
          createdAt: '01-01-01 2020'
        },
        {
          id: 2,
          title: 'third prize',
          photo: 'https://westonconferences.com/cmoon_images/20191212205249801_award.jpg',
          createdAt: '01-01-01 2020'
        },
        {
          id: 3,
          title: 'fourth prize',
          photo: 'https://westonconferences.com/cmoon_images/20191212205249801_award.jpg',
          createdAt: '01-01-01 2020'
        }
      ]
    }

    const totalItems = computed(() => fakeApiData.totalItems)

    const empty = computed(() => fakeApiData.totalItems === 0)

    const emptyStateData = computed(() => {
      return {
        title: nl.EMPTY_TABLE.PRIZES.TITLE,
        text: nl.EMPTY_TABLE.PRIZES.TEXT,
        buttonText: nl.EMPTY_TABLE.PRIZES.BUTTON_TEXT,
        buttonAction: createPrize,
        icon: require('@/assets/icons/icn_prize.svg')
      }
    })

    const tableData = computed(() => fakeApiData.items)
    //This should be got from the api aswell in the future.
    const paginationData = computed(() => {
      return {
        totalPages: fakeApiData.totalPages,
        currentPage: fakeApiData.currentPage
      }
    })

    function createNewPrize() {
      router.push({ name: ROUTE_NAMES_CMS.CREATE_PRIZE })
    }

    function manageSearch(searchTerm = undefined, page = 1, sortingValue = undefined) {
      console.log("doesn't exist yet for api", searchTerm, page, sortingValue)
    }


    function deletePrize(prize) {
      modalController.value.setModal(BASIC_MODAL_CONTENT.DELETE_PRIZE(prize.title), () => postDeletePrize(prize.id), null, null, MODAL_NAMES.DELETE_PRIZE)

      console.log('Deleting question', prize)
    }

    function postDeletePrize(prize) {
      console.log('SEND TO API delete question', prize)
    }


    function editPrize(prize) {
      router.push({ name: ROUTE_NAMES_CMS.EDIT_PRIZE, params: { id: prize.id } })
    }

    function createPrize() {
      router.push({ name: ROUTE_NAMES_CMS.CREATE_PRIZE })
    }



    return {
      prizesTableOptions: getTableOptions(PRIZES_TABLE),
      tableData,
      paginationData,
      totalItems,
      manageSearch,
      deletePrize,
      editPrize,
      empty,
      ROUTE_NAMES_CMS,
      emptyStateData,
      isBasicVersionDisplayed,
      createNewPrize
    }
  }
}
</script>

<style>

</style>